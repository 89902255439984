<template>
  <div>
    <tab-bar-wrapper @newRow="addCompany"/>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        :contextMenuItems="contextMenuItems"
        :query="query"
        :filter="filter"
        :componentName="$options.name"
        :columns="columns"
        :nested-grid="true"
        @ready="ready"
        @setComments="setComments"
        @setMasterObject="selectedClient = $event"
        @cell-key-down="gridKbEventSender"
      />
    </div>
    <action-bar-wrapper
      :commentList="commentList"
      @saveComments="updateComments"
    />
    <v-dialog
      lazy
      :width="dialogWidth"
      v-model="showDialog"
      persistent
    >
      <add-contact
        v-if="dialog.component === 'newContact'"
        :client-name="selectedClient.name"
        :client-id="selectedClient.id"
        @cancel="closeDialog()"
      />
      <add-address
        v-if="dialog.component === 'newAddress'"
        :owner="selectedClient.id"
        :owner-name="selectedClient.name"
        :event-only="false"
        @close="closeDialog()"
      />
      <add-carrier-account
        v-if="dialog.component === 'carrierAccount'"
        :client="selectedClient.id"
        @cancel="closeDialog()"
      />
      <change-client-type
        v-if="dialog.component === 'changeClientType'"
        :client="selectedClient"
        @cancel="closeDialog()"
        @success="changeTypeSuccess($event.value)"
      />
    </v-dialog>
  </div>
</template>
<script>
import TabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import ActionBarWrapper from '@/components/wrappers/actionBarWrapper.vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'
import grid from '../../components/mixins/grid'
import mutateClients from '@/api/graphql/mutationsJS/mutateClients'
import columns from '../../components/mixins/columns'
import addShippingAccount from '../../components/dialogs/addShippingAccount'
import newContact from '@/components/templates/newContact'
import { GET_CLIENTS__GRID, UPDATE_CLIENT } from '@/api/graphql/Constants/Clients'
import { COLUMNS__CLIENTS } from '@/lib/agGridColumnConfiguration'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__CLIENTS } from '@/lib/agGridKbShortCuts'
import { CONTEXT_MENU__CLIENTS } from '@/lib/agGridContextMenuConfigurations'
import ChangeClientType from '@/components/dialogs/ChangeClientType'
import newAddress from '@/components/templates/newAddress'
export default {
  name: 'clients',
  mixins: [grid, columns, mutateClients],
  components: {
    'tab-bar-wrapper': TabBarWrapper,
    'action-bar-wrapper': ActionBarWrapper,
    'grid-wrapper': GridWrapper,
    'add-contact': newContact,
    'add-address': newAddress,
    'add-carrier-account': addShippingAccount,
    'change-client-type': ChangeClientType
  },
  computed: {
    showDialog () {
      return this.dialog.app === 'clients'
    },

    dialog () {
      return this.$store.state.grid.dialog
    }
  },
  watch: {
    clients: function () {}
  },
  data () {
    return {
      dialogWidth: '80%',
      contextMenuItems: params => CONTEXT_MENU__CLIENTS(params, this.contextMenuCallback),

      commentList: {
        'notes': {
          name: 'notes',
          label: 'Notes',
          text: '',
          editable: true,
          show: true
        },
        'id': false
      },

      query: GET_CLIENTS__GRID,
      filter: [{ key: 'is_our_client', value: true }],

      columns: COLUMNS__CLIENTS,
      selectedClient: { id: '', name: '' }
    }
  },
  methods: {
    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__CLIENTS(params, this.contextMenuCallback), [])
    },

    contextMenuCallback (params) {
      if (params.dataKey) this[params.dataKey] = params.data
      if (params.functionToRun) this[params.functionToRun](params.params)
    },

    updateComments (comment) {
      const updaters = {
        [comment.name]: comment.text,
        id: this.selectedClient.id
      }
      const mutation = {
        mutation: UPDATE_CLIENT,
        variables: {
          input: updaters
        }
      }
      this.$apollo.mutate(mutation)
        .then(() => {
          this.$store.dispatch('notifications/createSnackbar', {
            message: 'Updated comments!',
            color: 'success'
          })
        })
        .catch(error => {
          this.$store.dispatch('notifications/createSnackbar', {
            message: error.message,
            color: 'error'
          })
        })
    },

    changeTypeSuccess (client) {
      // const node = this.gapi.getRowNode(client.id)
      // node.data.type = client.type
      // this.gapi.redrawRows({ rowNodes: [ node ]})
    },

    closeDialog () {
      this.$store.dispatch('grid/resetDialog')
    },

    addCompany () {
      this.$store.dispatch('grid/changeSheet', { app: 'topbar', component: 'newClient' })
    }
  }
}
</script>
