<template>
    <v-card>
      <v-card-title class="light-blue darken-4">
        <v-layout justify-space-between>
          <h3 class="white--text">Change Client Type</h3>
          <h4 class="white--text">{{ name }}</h4>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-xl>
          <v-layout row justify-center align-center>
            <v-flex xs6>
              <v-select
                v-model="type"
                label="Select a Client Type"
                :loading="loading"
                :items="types"
                item-text="choice"
                item-value="id"
              >
              </v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-layout row justify-start>
          <t-alert :message="message" :type="messageType"/>
          <v-spacer/>
          <cancel-button :success="messageType === 'success'" @click="$emit('cancel')"/>
          <submit-button :disabled="!isDifferent || messageType === 'success'" :loading="mutating" @click="submit"/>
        </v-layout>
      </v-card-actions>
    </v-card>
</template>

<script>
import { UPDATE_CLIENT } from '@/api/graphql/Constants/Clients'
import tAlert from '@/components/notifications/tAlert'
import { GET_CHOICES_CHOICES } from '@/api/graphql/Constants/Choices'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default {
  name: 'ChangeClientType',
  components: {
    't-alert': tAlert,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  computed: {
    name () {
      return this.client?.name ?? ''
    },

    clientType () {
      return this.client?.type.id
    },

    isDifferent () {
      return this.clientType !== this.type
    }
  },
  data () {
    return {
      type: 0,
      types: [],
      loading: false,
      mutating: false,

      message: '',
      messageType: 'info'
    }
  },
  apollo: {
    types: {
      query: GET_CHOICES_CHOICES,
      variables: {
        input: {
          filters: [{ key: 'app_name', value: 'clients' }, { key: 'model_name', value: 'client' }, { key: 'field_name', value: 'type' }]
        }
      },
      update: data => data.choices_choices,
      watchLoading (isLoading) {
        this.loading = isLoading
        this.type = this.client.type.id
      }
    }
  },
  methods: {
    submit () {
      this.mutating = true
      this.$apollo.mutate({
        mutation: UPDATE_CLIENT,
        variables () { return { input: { id: this.client.id, type_id: this.type } } }
      }).then(({ data: { Update__Clients_Client } }) => {
        this.message = 'Change client type successfully'
        this.messageType = 'success'
        this.$emit('success', { value: Update__Clients_Client })
      }).catch(error => {
        this.message = error
        this.messageType = 'error'
      }).finally(() => {
        this.mutating = false
      })
    }
  }
}
</script>
