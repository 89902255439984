import gql from 'graphql-tag'
import errorHandling from '../errorHandling'
import { GET_CLIENTS__GRID as GET_CLIENTS, CREATE_CLIENT } from '@/api/graphql/Constants/Clients'

const clientQueryInput = {
  excludes: [],
  filters: [{ key: 'is_our_client', value: true }],
  limit: 51,
  offset: 0,
  order_by: []
}

export default {
  mixins: [errorHandling],
  methods: {
    /**
     * Creates a client via gql
     * @param client
     * @return {number} id pk of the client
     */
    async createClient (client) {
      try {
        const response = await this.$apollo.mutate({
          mutation: CREATE_CLIENT,
          variables: { input: client },
          update (store, { data: { Create__Clients_Client } }) {
            const client = Create__Clients_Client
            try {
              const data = store.readQuery({
                query: GET_CLIENTS,
                variables: { input: clientQueryInput }
              })
              data.clients.unshift(client)
              store.writeQuery({ query: GET_CLIENTS, variables: { input: clientQueryInput }, data: data })
            } catch (error) {
              try {
                const data = { clients: [] }
                data.clients.push(client)
                store.writeQuery({ query: GET_CLIENTS, variables: { input: clientQueryInput }, data: data })
              } catch (error1) {
                this.$apollo.mutate({
                  mutation: gql`
                    mutation DeleteClient {
                      Delete__Clients_Client (id: ${client.id}) {
                        id
                      }
                    }
                  `
                })
              }
            }
          }
        })
        return response.data.client.id
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    },

    /**
     * Changes default terms for a client
     * @param data
     * @return {Promise<void>}
     */
    async changeClientTerms (data) {
      try {
        const response = await this.$apollo.query({
          query: gql`query CheckClientTerms ($client: Any!) {
            terms: clients_client_terms (input: { filters: [{ key: "client_id", value: $client },
              { key: "terms_id", value: ${data.terms_id}}] }) {
              id
            }
          }`,
          variables: { client: data.client_id, terms: data.terms_id }
        })
        if (response.data.terms.length !== 0) {
          data.id = response.data.terms[0].id
          await this.$apollo.mutate({
            mutation: gql`mutation ChangeClientTerms ($input: Update__Clients_ClientTerms__Input!) {
              terms: Update__Clients_ClientTerms (input: $input) {
                id
              }
            }`,
            variables: { input: data }
          })
        } else {
          await this.$apollo.mutate({
            mutation: gql`mutation CreateNewClientTerms ($input: Create__Clients_ClientTerms__Input!) {
              terms: Create__Clients_ClientTerms (input: $input) {
                id
              }
            }`,
            variables: { input: data }
          })
        }
      } catch (error) {
        throw new Error(this.errorHandler(error))
      }
    }
  }
}
