<template>
  <v-card>
    <v-card-title class="indigo">
      <h3 class="white--text">Add a Contact to {{ clientName }}</h3>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-xl>
        <v-form
          ref="contact"
          lazy-validation
          v-model="validated"
        >
          <v-layout row wrap justify-center align-center>
            <!-- First Name -->
            <v-flex xs3>
              <v-text-field
                v-model="contact.firstName"
                box
                label="First Name"
                :rules="[rules.required]"
              ></v-text-field>
            </v-flex>
            <!-- Last Name -->
            <v-flex xs3>
              <v-text-field
                v-model="contact.lastName"
                box
                label="Last Name"
                :rules="[rules.required]"
              ></v-text-field>
            </v-flex>
            <!-- Email -->
            <v-flex xs4>
              <v-text-field
                v-model="contact.email"
                box
                label="Email"
              ></v-text-field>
            </v-flex>
            <!-- Phone Number -->
            <v-flex xs3>
              <v-text-field
                v-model="contact.phone"
                box
                label="Phone Number"
              ></v-text-field>
            </v-flex>
            <!-- Fax Number -->
            <v-flex xs3>
              <v-text-field
                v-model="contact.fax"
                label="Fax Number"
                placeholder="Optional"
              ></v-text-field>
            </v-flex>
            <!-- Main Checkboxes -->
            <v-flex xs12>
              <v-layout row justify-center>
                <v-flex xs3>
                  <v-checkbox
                    v-model="contact.isMainBilling"
                    label="Is default bill to"
                    color="info"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs3>
                  <v-checkbox
                    v-model="contact.isMainRemitTo"
                    label="Is default remit to"
                    color="info"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs3>
                  <v-checkbox
                    v-model="contact.isMainShipTo"
                    label="Is default ship to"
                    color="info"
                  ></v-checkbox>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-form>
      </v-container>
    </v-card-text>
      <v-card-actions>
        <v-layout row justify-start align-center>
          <t-alert :message="message" :type="type"/>
          <v-spacer></v-spacer>
          <cancel-button class="pr-3" :success="success" @click="cancel"/>
          <submit-button :loading="saving" :disabled="success || !validated" @click="save"/>
        </v-layout>
      </v-card-actions>
  </v-card>
</template>

<script>
import tAlert from '@/components/notifications/tAlert'
import { CREATE_CONTACT } from '@/api/graphql/Constants/Contacts'
import { PrepareClientUpdateForDefaultContactInfo } from '@/lib/Contacts'
import { UPDATE_CLIENT } from '@/api/graphql/Constants/Clients'
import { GridEvents } from '@/store/gridEvents'
import CancelButton from '@/components/buttons/CancelButton.vue'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default {
  name: 'newContact',
  components: {
    't-alert': tAlert,
    'cancel-button': CancelButton,
    'submit-button': SubmitButton
  },
  props: {
    clientName: {
      type: String,
      required: true
    },
    clientId: {
      type: [String, Number],
      required: true
    },
    saveName: {
      type: String,
      default: 'Submit'
    },
    cancelName: {
      type: String,
      default: 'Cancel'
    }
  },
  computed: {
    success () {
      return this.type === 'success'
    }
  },
  data () {
    return {
      saving: false,
      message: '',
      type: 'info',

      contact: {
        owner: null,
        isMainBilling: false,
        isMainShipTo: false,
        isMainRemitTo: false,

        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        fax: ''
      },

      validated: false,
      rules: {
        required: e => !!e || 'Required'
      }
    }
  },
  methods: {
    save () {
      if (this.$refs.contact.validate()) {
        this.message = ''
        this.type = 'info'
        this.saving = true
        const contactToMake = {
          ...(this.contact.email && { email: this.contact.email }),
          ...(this.contact.fax && { fax: this.contact.fax }),
          first_name: this.contact.firstName,
          last_name: this.contact.lastName,
          owner_id: this.clientId,
          ...(this.contact.phone && { phone: this.contact.phone })
        }

        this.$apollo.mutate({
          mutation: CREATE_CONTACT,
          variables: { input: contactToMake }
        }).then(({ data: { Create__Clients_Contact } }) => {
          GridEvents.$emit('refetch-nested-client')
          const updater = PrepareClientUpdateForDefaultContactInfo({
            clientId: this.clientId,
            contactId: Create__Clients_Contact.id,
            billing: this.contact.isMainBilling,
            remit: this.contact.isMainRemitTo,
            shipTo: this.contact.isMainShipTo
          })
          if (updater !== false) {
            this.$apollo.mutate({
              mutation: UPDATE_CLIENT,
              variables: { input: updater }
            }).then(() => {
              this.resultHandler({ message: 'Created contact successfully', type: 'success' })
            }).catch(() => {
              this.resultHandler({ message: 'Created contact but could not update selected defaults.', type: 'success' })
            })
          } else {
            this.resultHandler({ message: 'Created contact successfully', type: 'success' })
          }
        }).catch(() => {
          this.resultHandler({ message: 'Could not create contact, verify info and try again', type: 'error' })
        }).finally(() => {
          this.saving = false
        })
      }
    },

    cancel () {
      this.$emit('cancel')
    },

    resultHandler ({ message, type }) {
      this.message = message
      this.type = type
    }
  }
}
</script>

<style scoped>

</style>
