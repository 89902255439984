export interface ClientDefaultContactUpdateInput {
  id: string
  default_billing_contact_id?: string
  default_remit_contact_id?: string
  default_ship_to_contact_id?: string
}

export interface ReceivedInputForPreparingClientUpdate {
  clientId: string,
  contactId: string,
  billing: boolean,
  remit: boolean,
  shipTo: boolean
}

export function PrepareClientUpdateForDefaultContactInfo (info: ReceivedInputForPreparingClientUpdate): ClientDefaultContactUpdateInput | false {
  const updater: ClientDefaultContactUpdateInput = { id: info.clientId }
  if (info.billing || info.remit || info.shipTo) {
    if (info.billing) updater.default_billing_contact_id = info.contactId
    if (info.remit) updater.default_remit_contact_id = info.contactId
    if (info.shipTo) updater.default_ship_to_contact_id = info.contactId
    return updater
  } else {
    return false
  }
}
